// init base functions
$(window).on('load', function () {
    classOnBody($('main').attr('id'));
    scrollDirection();
    initGettersAndSetters();
    changeTouchClickText();
});

function maskFone(o, f) {
    setTimeout(function() {
        var v = mphone(o.value);
        if (v != o.value) {
            o.value = v;
        }
    }, 1);
}

function mphone(v) {
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
        r = r.replace(/^(\d*)/, "$1");
    }
    return r;
}

$(document).on('keypress','.foneMask',function(){
    maskFone(this, mphone);
});

$(document).on('blur','.foneMask',function(){
    maskFone(this, mphone);
});



// general input masks
if (is.not.ie()) {
    $('.cepMask').mask("99999-999");
    $('.cpfMask').mask("999.999.999-99");
    $('.dataMask').mask("99/99/9999");
}

// init AOS
if (is.not.ie()) {
    AOS.init();
} else {
    $('*').removeAttr("data-aos");
}

// components
if ($('.liSelect').length) {
    $('.liSelect select').on('focusin', function () {
        $(this).parent('.liSelect').addClass('is-active');
    });
    $('.liSelect select').on('focusout', function () {
        $(this).parent('.liSelect').removeClass('is-active');
    });
}

// modal
/*$('.u-modal__close, .u-modal__mask').on('click', function() {
    $('.u-modal').removeClass('is-active');
    $('.u-modal__mask').removeClass('is-active');
});*/
$(document).on('click','.u-modal__close', function() {
    $('.u-modal').removeClass('is-active');
    $('.u-modal__mask').removeClass('is-active');
});
$(document).on('click','.u-modal__mask', function() {
    $('.u-modal').removeClass('is-active');
    $('.u-modal__mask').removeClass('is-active');
});

$('.js-openModalContato').on('click', function (e) {
    e.preventDefault();
    $('.s-contato').addClass('is-active');
    $('.u-modal__mask').addClass('is-active');
});

// functions
function showMask() {
    nav_el.mask.addClass(nav_classActive);
}
function hideMask() {
    nav_el.mask.removeClass(nav_classActive);
}
function closeAllSubMenus() {
    nav_el.liWithOneLevelSubMenu.parent('li').removeClass(nav_classActive);
    nav_el.liWithTwoLevelsSubMenu.parent('li').removeClass(nav_classActive);
}
function overflowBlocked() {
    nav_el.body.addClass('u-overflowBlocked');
}
function overflowFree() {
    nav_el.body.removeClass('u-overflowBlocked');
}
function closeNavContent() {
    nav_el.navContentHero.removeClass(nav_classActive);
}
function openNavContent() {
    nav_el.navContentHero.addClass(nav_classActive);
}
function inactiveHamburguer() {
    nav_el.hamburguer.removeClass(nav_classActive);
}
function activeHamburguer() {
    nav_el.hamburguer.addClass(nav_classActive);
}

function closeAllMenuItens() {
    hideMask();
    closeAllSubMenus();
    overflowFree();
    closeNavContent();
    inactiveHamburguer();
}
function openAllMenuItens() {
    showMask();
    overflowBlocked();
    openNavContent();
    activeHamburguer()
}

// nav
if ($('.l-nav').length) {
    // vars
    var nav_scrollInit = 0;
    var nav_classActive = 'is-active';
    var nav_el = {
        body: $('body'),
        hamburguer: $('.c-hamburguer'),
        mask: $('.l-nav__maskMenu'),
        nav: $('.l-nav'),
        navContentHero: $('.l-nav__contentHero'),
        liWithOneLevelSubMenu: $('.liWithOneLevelSubMenu > a'),
        liWithTwoLevelsSubMenu: $('.liWithTwoLevelsSubMenu > a'),
        liWithoutSubMenu: $('.liWithoutSubMenu > a'),
        firstSubMenu: $('.firstSubMenu'),
        secondSubMenu: $('.secondSubMenu'),
        openSecondSubMenu: $('.openSecondSubMenu'),
        linkScroll: $('.js-linkScroll'),
    };

    // effect on scroll
    $(window).on('scroll', function(event) {
        var scrollBody = $(this).scrollTop();

        // scroll up to 99
        if (scrollBody > 99) {
            nav_el.nav.addClass('scrolled');
        } else {
            nav_el.nav.removeClass('scrolled');
        }

        // middle class
        // if (scrollBody > 600) {
        //     nav_el.nav.addClass('hidden');
        //     nav_el.nav.addClass('scrolledMiddle');
        // } else {
        //     nav_el.nav.removeClass('hidden');
        //     nav_el.nav.removeClass('scrolledMiddle');
        // }

        // scroll up or down
        if (scrollBody < nav_scrollInit) {
            nav_el.nav.removeClass('hidden');
            nav_el.nav.addClass('scrolledUp');
            nav_el.nav.removeClass('scrolledDown');
        } else {
            nav_el.nav.removeClass('scrolledUp');
            nav_el.nav.addClass('scrolledDown');
        }

        // close menus on hidden nav
        if(nav_el.nav.hasClass('hidden')) {
            closeAllMenuItens();
        }

        // reference var
        nav_scrollInit = scrollBody;
    });
    $(window).on('load', function(event) {
        var scrollBody = $(this).scrollTop();
        if (scrollBody > 1) {
            nav_el.nav.addClass('scrolled');
        } else {
            nav_el.nav.removeClass('scrolled');
        }
    });

    // open/close/menus/submenus/hamburguer on clicks
    nav_el.hamburguer.on('click', function() {
        if ($(this).hasClass(nav_classActive)) {
            closeAllMenuItens();
        } else {
            openAllMenuItens();
        }
    });
    nav_el.mask.on('click', function() {
        closeAllMenuItens()
    });
    nav_el.liWithoutSubMenu.on('click', function() {
        closeAllMenuItens();
    });
    nav_el.firstSubMenu.children('li').on('click', function() {
        if(!$(this).hasClass('openSecondSubMenu')) {
            closeAllMenuItens();
        }
    });
    nav_el.secondSubMenu.children('li').on('click', function() {
        closeAllMenuItens();
    });

    // nav
    nav_el.linkScroll.on('click', function (e) {
        e.preventDefault();
        var finalDestiny = $(this).attr('href');
        goToSection__scroll(finalDestiny, 100, 50, 700, 10);
    });
}

// modules
if ($('.m_whatsApp').length) {
    var wpp__parts = {
        link_mobile: 'http://api.whatsapp.com/',
        link_web: 'https://web.whatsapp.com/',
        phone: 'send?phone=',
        msg: 'text='
    };
    $('.js-openWhatsModule').on('click', function(e) {
        e.preventDefault();
        var wppNumber = $(this).attr("data-whatsNumber");
        openWhatsApp(wppNumber);
    });
    function openWhatsApp(number) {
        if (is.iphone() || is.androidPhone() || is.windowsPhone() || is.blackberry() || is.mobile()) {
            window.open(wpp__parts.link_mobile + wpp__parts.phone + number, '_blank');
        } else {
            window.open(wpp__parts.link_web + wpp__parts.phone + number, '_blank');
        }
    }
}

function showMessageOnIE(warnLevel) {
    // warnLevel = 1 -> Remover aviso
    // warnLevel = 2 -> Recolher aviso
    // warnLevel = 3 -> Bloquear navegação

    var ieDetectEl = $('#w_ieDetect');
    var ieDetectEl__mask = $('#w_ieDetect__mask');
    var ieDetectEl__unlock = $('.w_ieDetect__unlockSite');

    if (is.ie()) {
        if(warnLevel === 1) {
            $('body').css('overflow', 'hidden');
            ieDetectEl.removeClass('u-dnone').addClass('is-visible');
            ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');
            ieDetectEl__unlock.on('click', function() {
                ieDetectEl.remove();
                ieDetectEl__mask.remove();
                $('body').css('overflow-y', 'auto');
                $('body').css('overflow-x', 'hidden');
            });
        }
        if(warnLevel === 2) {
            //block overflow and show modal
            $('body').css('overflow', 'hidden');
            ieDetectEl.removeClass('u-dnone').addClass('is-visible');
            ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');

            // hide modal and mask
            ieDetectEl__unlock.on('click', function() {
                $(this).remove();
                ieDetectEl__mask.remove();
                ieDetectEl.removeClass('is-visible');
                ieDetectEl.addClass('is-fixed');
                $('body').css('overflow-y', 'auto');
                $('body').css('overflow-x', 'hidden');
            });
        }
        if(warnLevel === 3) {
            $('nav').remove();
            $('header').remove();
            $('footer').remove();
            $('main').remove();
            ieDetectEl__unlock.remove();
            $('body').css('overflow', 'hidden');
            ieDetectEl.removeClass('u-dnone').addClass('is-visible');
            ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');
        }
    } else {
        ieDetectEl.remove();
        ieDetectEl__mask.remove();
    }
}
if ($('#w_ieDetect').length) {
    $(window).on('load', showMessageOnIE(3));
}
if ($('.auxElements__grid').length) {
    $('.auxElements__controls .toggleGrid').on('click', function() {
        $('.auxElements__controls .toggleGrid').toggleClass('is-active');
        $('.auxElements__grid').toggleClass('u-dnone').toggleClass('is-active');
        $('.auxElements__grid .line').toggleClass('is-active');
    });
}

function identifyScroll() {
    returnScroll = 1;
    if ($('body').hasClass('scrolledDown')) {
        returnScroll = 'Down';
    } else if($('body').hasClass('scrolledUp')) {
        returnScroll = 'Up';
    } else {
        returnScroll = 'Static';
    }
    return returnScroll;
}
function tasksAuxVars() {
    $('.auxElements__controls .scrollEfetuado').html(Math.round($(window).scrollTop()));
    $('.auxElements__controls .scrollDirection').html(identifyScroll());
    $('.auxElements__controls .viewportWidth').html(Math.round($(window).width()));
    $('.auxElements__controls .viewportHeight').html(Math.round($(window).height()));
    $('.auxElements__controls .navHeight').html(Math.round(get__navHeight()));
}
if ($('.auxElements__controls').length) {

    $(document).on('ready', function () {
        tasksAuxVars();
    });
    $(window).on('load', function () {
        tasksAuxVars();
    });
    $(window).on('resize', function () {
        tasksAuxVars();
    });
    $(window).on('scroll', function () {
        tasksAuxVars();
    });
}

function validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g,'');
    if(cpf == '') return false;
    // Elimina CPFs invalidos conhecidos
    if (cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999")
        return false;
    // Valida 1o digito
    add = 0;
    for (i=0; i < 9; i ++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
        rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
        return false;
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i ++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
        rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
        return false;
    return true;
}

function verificaNome(nome){
    var reTipo = /[A-z][ ][A-z]/;
    return reTipo.test(nome);
}

jQuery.validator.addMethod("cpfValida", function(value, element) {
    return this.optional(element) || validarCPF(value);
}, "Digite um CPF válido");

jQuery.validator.addMethod("nomeValida", function(value, element) {
    return this.optional(element) || verificaNome(value);
}, "Digite o nome completo");

if ($('#formContato').length) {
    $("#formContato").validate({
        submitHandler: function (form) {
            //SUBMIT form
            $(form).ajaxSubmit({
                //target: 'status-envio',
                beforeSubmit: function () {
                    $('#formContato .c-stsSend').removeClass('u-dnone');
                    $('#formContato .c-stsSend__feedback--load').removeClass('u-dnone');
                    $('#formContato  #btnContato').attr('disabled', true);
                },
                success: function (result) {
                    result = result.split('|');
                    if(parseInt(result[1]) == 1){
                        $('#formContato .c-stsSend__feedback').addClass('u-dnone');
                        $('#formContato .c-stsSend__feedback--success').removeClass('u-dnone');
                        setTimeout(function(){
                            $('#formContato #btnContato').attr('disabled',false);
                            $("#formContato .field").val('');
                            $("#formContato textarea").val('').html('');
                            $("#formContato .field").removeClass('error');
                            $('#formContato .c-stsSend').addClass('u-dnone');
                            $('#formContato .c-stsSend__feedback').addClass('u-dnone');
                        },4000);
                    }else{
                        $('#formContato .c-stsSend__feedback').addClass('u-dnone');
                        $('#formContato .c-stsSend__feedback--error').removeClass('u-dnone');
                        $('#formContato .c-stsSend__feedback--error span').html(result[2]);
                        setTimeout(function () {
                            $('#formContato .c-stsSend').addClass('u-dnone');
                            $('#formContato .c-stsSend__feedback').addClass('u-dnone');
                            $('#formContato #btnContato').attr('disabled', false);
                        }, 3000);
                    }
                }
            });
        }
    });
    $(document).on('change','#formContato_estado',function(){
        var uf = $(this).val();
        $("#formContato_cidade").html('<option>Carregando cidades...</option>');
        $.ajax({
            type: "POST",
            data: {uf: uf},
            url: "ajax-cidades.php",
            success: function (result) {
                $("#formContato_cidade").html(result);
            }
        });
    });
}

$('#beneficios>ul>li').on('click',function(){
    var element = $(this);
    if(element.hasClass('active')){
        element.removeClass('active');
        /*element.children('.contentBeneficio').children('p').fadeOut(function(){
            element.children('.contentBeneficio').children('i,h4').fadeIn();
            element.removeClass('active');
        });*/
    }else{
        element.addClass('active');
        /*element.children('.contentBeneficio').children('i,h4').fadeOut(function(){
            element.children('.contentBeneficio').children('p').fadeIn();
            element.addClass('active');
        });*/
    }
});

// tab
$('.c-tab__head').on('click', function () {
    $(this).parent().toggleClass('is-active');
});

// u sticky
$(window).on('scroll', function () {stickyPosition('.u-stickyHeader', 20, get__navHeight() + 20);});
$(window).on('load', function () {stickyPosition('.u-stickyHeader', 20, get__navHeight() + 20);});